export default {
  name: 'SavedAccessories',
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  methods: {
    reverseRouteName (str, defaultName = 'route-name') {
      return this.$parent.reverseRouteName(str, defaultName)
    }
  }
}
